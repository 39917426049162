<template>
  <div class="border-t bg-white border-b border-gray-200">
    <div class="container-xl px-4 md:px-2 py-4 h-20 md:h-20 mx-auto flex flex-row items-center justify-between">
      <div class="mr-auto">
        <h4 class="text-xl font-semibold leading-tight text-gray-800 flex items-center">
          <slot></slot>
        </h4>
      </div>
      <div class="ml-auto">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `WrapperSubHeader`,
  data: function () {
    return {}
  }
}
</script>
