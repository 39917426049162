<template>
  <div>
    <WrapperSubHeader>
      <span
        >{{ $t("users.users") }} <span class="font-normal text-base">/ {{ $t("users.edit") }}</span></span
      >
    </WrapperSubHeader>

    <div class="w-full my-5 mt-12">
      <div class="mx-auto container">
        <div class="max-w-xl mx-auto">
          <div v-if="loading" class="w-full mt-5 flex justify-center items-center mx-auto sm:max-w-md px-5 sm:p-0">
            <Loader :onlyLoader="true" />
          </div>
          <div v-if="!loading">
            <!-- Form -->
            <div class="w-full mx-auto sm:max-w-md px-5 sm:p-0">
              <div class="w-full mx-auto sm:rounded-lg md:max-w-lg mb-7 text-center">
                <h2 class="text-base font-semibold text-gray-800 mb-3.5 capitalize">{{ $t("users.editUser") }}o✏</h2>
              </div>
              <Card>
                <template #content>
                  <BlockUI :baseZIndex="100" :autoZIndex="false">
                    <div class="flex flex-col w-full px-2">
                      <form @submit="updateUser" :validation-schema="editUserSchema">
                        <!-- Name field -->
                        <div class="form-group">
                          <label for="name" class="form-label">{{ $t("users.name") }}</label>
                          <InputText v-model="name" id="name" name="name" type="text" class="p-inputtext-sm" />
                        </div>

                        <!-- Email field -->
                        <div class="form-group">
                          <label for="email" class="form-label">{{ $t("users.email") }}</label>
                          <InputText v-model="email" id="email" name="email" type="text" class="p-inputtext-sm" />
                        </div>

                        <!-- Status field -->
                        <div class="mt-3">
                          <label class="cursor-pointer label px-3 py-3 rounded-md border border-dashed border-gray-300">
                            <span class="label-text text-gray-900 font-semibold text-sm">{{ $t("users.state") }}</span>
                            <div>
                              <input type="checkbox" class="checkbox checkbox-accent" v-model="is_active" />
                              <span class="checkbox-mark"></span>
                            </div>
                          </label>
                        </div>

                        <!-- Form submit button -->
                        <Button type="submit" :label="$t('users.modify')" iconPos="right" class="mt-3 p-button-primary p-button-lg w-full" />
                      </form>
                    </div>
                  </BlockUI>
                </template>
              </Card>

              <!--Back button -->
              <router-link :to="{ name: 'Admin Users' }" class="flex justify-center items-center">
                <Button :label="$t('users.back')" class="mt-3 p-button-secondary p-button-text" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WrapperSubHeader from "@/components/WrapperSubHeader"
import Loader from "@/components/UI/Loader"
import * as yup from "yup"

import Card from "primevue/card"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import BlockUI from "primevue/blockui"

export default {
  name: "UserEdit",
  props: ["id"],
  components: {
    WrapperSubHeader,

    Loader,
    Card,
    Button,
    InputText,
    BlockUI
  },

  data() {
    const editUserSchema = yup.object({
      name: yup.string().required(),
      email: yup.string().required().email()
    })

    return {
      editUserSchema,
      name: "",
      email: "",
      is_active: true,
      loading: false,
      updating: false,
      updateError: false,
      updateErrorMessage: false,
      updateOK: false,
      original: {}
    }
  },

  mounted() {
    this.loading = true
    this.$store.dispatch("AdminUsers/show", this.id).then((response) => {
      if (response.status == 500) {
        this.$toast.error("Ocurrió un error, por favor reintente más tarde")
      }
      if (response.status == 200) {
        this.name = response.data.data.name
        this.email = response.data.data.email
        this.is_active = response.data.data.is_active
        this.original = response.data.data
      }
      this.loading = false
    })
  },

  methods: {
    updateUser(values) {
      let id = this.id

      this.updateError = false
      this.updateErrorMessage = ""
      this.updateOK = false
      this.updating = true

      this.$store
        .dispatch("AdminUsers/update", { id, ...values })
        .then((response) => {
          if (response.status == 500) {
            this.$toast.error("Ocurrió un error, por favor reintente más tarde")
          }

          if (response.status == 422) {
            this.updateErrorMessage = Object.keys(response.data.errors)
              .map(function (errorKey) {
                return response.data.errors[errorKey].join(",")
              })
              .join(",")
            this.updateError = true
          }

          if (response.status == 204) {
            this.updateOK = true
            this.$toast.success(`Usuario ${values.name} modificado con éxito`)
          }
          this.updating = false
        })
        .catch((err) => {
          this.$toast.error(err)
          console.log(err)
        })

      if (this.original.is_active != this.is_active) {
        this.$store
          .dispatch("AdminUsers/enabled", { id, is_active: this.is_active })
          .then((response) => {
            if (response.status == 500) {
              this.$toast.error("Ocurrió un error, por favor reintente más tarde")
            }

            if (response.status == 204) {
              this.updateOK = true
              if (this.is_active) {
                this.$toast.success(`Usuario ${values.name} habilitado con éxito`)
              } else {
                this.$toast.success(`Usuario ${values.name} deshabilitado con éxito`)
              }
            }
            this.updating = false
          })
          .catch((err) => {
            this.$toast.error(err)
            console.log(err)
          })
      }
    }
  }
}
</script>
